import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

const VST = () => {
  return (
    <Container>
      <Card className="my-3">
        <Card.Body>
          <Card.Title>Vibrational Sound Therapy</Card.Title>
          <p className="vst-text">
            Absorbing frequency to help bring the body into balance and
            restoration using tuning forks, world resonate instruments including
            gong, crystal singing bowls, and the human vst.
          </p>
          <p className="vst-text">
            available for private sessions and group sound baths at the studio
            or in your own home!
          </p>
          <p className="vst-text">
          <li>Helps in Stress Reduction and focuses on harmonizing the mind, body,
            and spirit!</li>
            <li>Helps clear blockages and stagnant energy.</li>
            <li>Improves concentration and mental clarity.</li>
            <li>Relieves common ailments and health problems like hypertension,
            stomach pain, depression, and joint pain.</li>
          </p>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default VST;
