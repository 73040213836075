import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import GoogleReviews from "./GoogleReviews";
import axios from "axios";

const MusicPage = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const page_id = process.env.REACT_APP_FACEBOOK_PAGE_ID;
      const access_token = process.env.REACT_APP_FACEBOOK_ACCESS_TOKEN;

      const postsUrl = `https://graph.facebook.com/v12.0/${page_id}/posts?fields=message,full_picture,created_time&access_token=${access_token}`;

      try {
        const response = await axios.get(postsUrl);
        if (response.status === 200) {
          const postsData = response.data.data.map((post) => ({
            id: post.id,
            message: post.message || "No message",
            created_time: post.created_time,
            image_url: post.full_picture || null,
          }));
          setPosts(postsData);
        } else {
          console.error(`Error fetching posts: ${response.status}`);
        }
      } catch (error) {
        console.error(`Error fetching posts: ${error.message}`);
      }
    };

    // fetchPosts();
  }, []);

  return (
    <Container>
      <Card className="my-3">
        <Card.Body>
          <Card.Title>Joni Clare - Music Services</Card.Title>
          <Card.Text>
            <div>
              Joni Clare is a talented songwriter, performer, recording artist,
              and educator based in the Northwoods of Wisconsin.
            </div>
            <div>
              <div>Explore our music services:</div>
              <ul>
                <li>Songwriting Workshops</li>
                <li>Private Music Lessons</li>
                <li>Recording Sessions</li>
                <li>Live Performances</li>
              </ul>
            </div>
            <div>
              Join us for an enriching musical experience, whether you are a
              beginner or an advanced musician.
            </div>
            <div>
              Contact us to learn more about how Joni Clare can help you achieve
              your musical goals.
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
      <Card>
        <iframe
          style={{ border: "0", width: "auto", height: "500px" }}
          src="https://bandcamp.com/EmbeddedPlayer/track=1159723914/size=large/bgcol=181a1b/linkcol=4c1da3/tracklist=false/transparent=true/"
          seamless
          title="Partner by Joni Clare"
        >
          <a href="https://joniclare.bandcamp.com/track/partner">
            Partner by Joni Clare
          </a>
        </iframe>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>Music Lessons</Card.Title>
          <Card.Text>
            <h2 className="voice-title">Voice</h2>
            <p className="voice-text">
              Studying voice helps improve your sense of self and open a special
              expression to others. Joni helps you discover and deepen your
              authentic creative vibration through traditional and
              nontraditional music education and methods. She holds space for
              your intentions plus invites you to tap into your confidence,
              whole-self presence, listening attention, and creative
              consciousness.
            </p>
            <p className="voice-text">
              She helps you strengthen your instrument through playful
              exercises, academic activities, and repertoire that interests you.
              Your time together is constructive while exploring many ideas that
              music offers. Her aim is to guide you to a free and sustainable
              sound that can be understood on a simple and clear level.
            </p>
            <h2 className="piano-title">Piano</h2>
            <p className="piano-text">
              In piano lessons, you will achieve musical skills and expression
              through music fundamentals, improvisation, mindfulness awareness,
              and performance if desired. Joni uses the Piano Adventures book
              series as a foundation, but time is also spent off book. She is
              also open to working on materials that you bring in.
            </p>
            <div>
              <h2 className="voice-title">Music Lessons</h2>
              Studio is located in Woodruff and Rhinelander. Inquire for rates
              and availability.
            </div>
            <div>Contact Us: (715) 490-4566</div>
          </Card.Text>
        </Card.Body>
      </Card>
      {/* <Card className="my-3">
                <Card.Body>
                    <Card.Title>Latest Facebook Posts</Card.Title>
                    {posts.length > 0 ? (
                        <Row>
                            {posts.map((post) => (
                                <Col md={6} lg={4} key={post.id} className="my-3">
                                    <Card>
                                        {post.image_url && (
                                            <Card.Img
                                                variant="top"
                                                src={post.image_url}
                                                className="facebook-post-image"
                                            />
                                        )}
                                        <Card.Body>
                                            <Card.Text>{post.message}</Card.Text>
                                            <Card.Footer>
                                                <small className="text-muted">
                                                    {new Date(post.created_time).toLocaleString()}
                                                </small>
                                            </Card.Footer>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <p>No posts available.</p>
                    )}
                </Card.Body>
            </Card> */}

      <Row>
        <Col>
          <GoogleReviews />
        </Col>
      </Row>
    </Container>
  );
};

export default MusicPage;
